<template>
  <dashboard-page-wrapper title="Team">
    <b-row>
      <b-col md="12">
        <card bodyClasses="p-0">
          <header class="py-1">
            <div class="d-flex justify-content-between px-4 py-2 align-items-center">
              <div class="d-flex flex-column align-items-start">
                <h3 class="mb-0"><div class="mr-3"><b>{{ seatsData.length }}/{{ totalSeats }}
                   seats filled</b></div></h3>
                <manage-seats/>
              </div>
              <div class="d-flex justify-content-end align-items-center px-4">
                <button class="btn btn-primary"
                        @click="showInvitationModal">
                  <i class="ni ni-send"></i>
                  Send invite
                </button>
              </div>
            </div>
          </header>
          <div>
            <team-table
              :seats="seats"
              :integration="atsIntegration"
              :integrationEnabled="atsIntegrationEnabled"
            ></team-table>
          </div>
        </card>
      </b-col>
    </b-row>
    <invite-member-modal ref="invitationModal" :seatsFilled="seatsFilled" :usedEmails="usedEmails"/>
  </dashboard-page-wrapper>
</template>
<script>
import DashboardPageWrapper from '@/components/App/UI/DashboardPageWrapper.vue';
import {mapActions, mapGetters} from 'vuex';
import TeamTable from './TeamTable.vue';
import InviteMemberModal from './InviteMemberModal';
import {FETCH_ATS_INTEGRATION_REQUEST, FETCH_SEATS_REQUEST} from '@/store/storeActions';
import {seatTypes} from '@/store/userConstants';
import {
  seatStatus,
  supportUrl,
} from '@/store/userConstants';
import ManageSeats from './ManageSeats.vue';

export default {
  components: {
    DashboardPageWrapper,
    TeamTable,
    InviteMemberModal,
    ManageSeats,
  },
  data() {
    return {
      helpUrl: `${supportUrl}5925486-how-to-manage-your-team-members`,
      seatTypes,
    };
  },
  computed: {
    ...mapGetters([
      'totalSeats',
      'availableSeats',
      'usedSeats',
      'accountSeats',
      'accountFreeUsersLoaded',
      'atsIntegration',
      'atsIntegrationEnabled',
      'integrationLoaded',
    ]),
    seats() {
      return this.accountSeats.filter(
        (seat) => seat.status === seatStatus.USED || seat.status === seatStatus.PENDING_INVITATION ||
        seat.status === seatTypes.NON_TEXTER);
    },
    seatsData() {
      return this.accountSeats.filter(
        (seat) => seat.seatType !== seatTypes.NON_TEXTER && (seat.status === seatStatus.USED || seat.status === seatStatus.PENDING_INVITATION));
    },
    usedEmails() {
      return this.seats.map((seat) => seat.metadata ? seat.metadata.invitedEmail: '');
    },
    seatsFilled() {
      return this.usedSeats >= this.totalSeats;
    },
  },
  mounted() {
    !this.integrationLoaded && this.FETCH_ATS_INTEGRATION_REQUEST();
  },
  methods: {
    ...mapActions([
      FETCH_SEATS_REQUEST,
      FETCH_ATS_INTEGRATION_REQUEST,
    ]),
    showInvitationModal: function() {
      this.$refs.invitationModal.displayToggle(true);
    },
  },
  created() {
    !this.accountFreeUsersLoaded && this.FETCH_SEATS_REQUEST();
  },
};
</script>
