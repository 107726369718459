<template>
  <div class="turn-on-office-modal">
    <modal
      :show="display"
      v-on:close="displayToggle(false)"
      footerClasses="d-none"
      headerClasses="app-border-b align-items-center"
    >
      <template v-slot:header>
          <h5 class="modal-title" id="exampleModalLabel">Turn on Out of Office for {{name}}</h5>
      </template>
      <ValidationObserver v-slot="{ invalid, validate }" ref="formValidator">
        <b-form @submit.prevent="validate().then(save)">
          <b-col md="12">
            <label class="form-control-label">Out of Office message</label>
            <div class="d-flex mb-2">
              <base-button
              v-for="(shortcode, index) in shortcodes"
              :key="`shortcode_${index}`"
              type="outline-primary"
              size="sm"
              @click="addShortcode(shortcode)"
              ><i class="ni ni-fat-add"></i>{{shortcode}}</base-button>
            </div>
            <app-textarea
              v-model="message"
              rules="required"
              ref="messageBlock"
              class="template-input"
            />
            <p class="d-flex justify-content-end"
            :class="{'text-danger' : message.length > messageLimit}">{{messageInstruction}}</p>
            <div class="d-flex justify-content-end">
              <button class="btn border-0 btn-outline-primary" @click.prevent="displayToggle(false)">
                Cancel
              </button>
              <button class="btn btn-primary" :disabled="invalid || !canSave">
                Turn on
              </button>
            </div>
          </b-col>
        </b-form>
      </ValidationObserver>
    </modal>
  </div>
</template>
<script>
import {mapActions, mapGetters} from 'vuex';
import AppTextarea from '@/components/App/controls/AppTextarea.vue';
import {UPDATE_USER_OFFICE_MSG_REQUEST} from '@/store/storeActions';
export default {
  components: {
    AppTextarea,
  },
  data: function() {
    if (this.activeUser) {
      console.log(this.activeUser.user.outOfOfficeMessage);
    }
    const shortcodes = [
      'FirstName',
      'LastName',
    ];
    return {
      id: '',
      message: '',
      shortcodes,
      messageLimit: 400,
      display: false,
      name: '',
      userId: null,
    };
  },
  watch: {
    message: {
      handler: function(value) {
        this.message = value;
      },
      immediate: true,
    },
    activeUser: {
      handler: function(value) {
        if (value && value.user) {
          this.name = value.user.firstName + ' '+ value.user.lastName;
          this.userId = value.user.id;
          this.message = value.user.outOfOfficeMessage;
        }
      },
      immediate: true,
    },
  },
  computed: {
    ...mapGetters([
      'outOfOfficeMessage',
    ]),
    messageInstruction: function() {
      return this.message.length+'/'+this.messageLimit;
    },
    canSave: function() {
      return (this.message.length <= this.messageLimit);
    },
  },
  props: {
    activeUser: Object,
  },
  methods: {
    ...mapActions([
      UPDATE_USER_OFFICE_MSG_REQUEST,
    ]),
    displayToggle: function(isDisplay) {
      this.display = isDisplay;
    },
    closeModalPreventHandler(e) {
      if (e.key === 'Enter') {
        e.preventDefault();
        this.$refs.formValidator.validate().then((isSuccess) => isSuccess && this.submit());
      }
    },
    save: function() {
      const payload = {
        outOfOfficeMessageEnabled: true,
        outOfOfficeMessage: this.message,
        userId: this.userId,
      };
      this.UPDATE_USER_OFFICE_MSG_REQUEST({payload});
      this.displayToggle(false);
    },
    addShortcode: function(shortcode) {
      let cursorPosition = 0;
      const {messageBlock} = this.$refs;
      if (!!messageBlock) {
        const {textarea} = messageBlock.$refs;
        textarea.focus();
        cursorPosition = textarea ? textarea.selectionStart : 0;
      }
      this.message = `${this.message.slice(0, cursorPosition)}{${shortcode}}${this.message.slice(cursorPosition)}`;
    },
    initializeFields: function(template) {
      if (!!template) {
        this.id = template.id;
        this.message = template.message.replace(/\\/g, '');
      } else {
        this.id = null;
        this.message = '';
      }
    },
  },
};
</script>
<style>
.template-input .form-control, .template-input.form-control{
  border-radius: 10px;
}
</style>
