<template>
    <div>
      <div class="d-flex justify-content-between align-items-center pt-2">
        <a class="text-center d-inline-block font-600 pre-small-text"
        @click="showAdditionSeatsModal"
        >Purchase seats
        </a>
        <a class="text-center d-inline-block font-600 pre-small-text mx-3"
        @click="deleteSeatsHandler"
        >Remove seat
        </a>
      </div>

      <info-modal
      header="Delete seats"
      ref="infoModal"
      ><template slot="body">
          <div>
            <div>Seats must be empty before they can be deleted. Please remove a team member from a seat.</div>
            <a class="d-inline-block mt-3 font-600" href="localhos:8080" target="blank"><u>Instructions</u></a>
          </div>
        </template>
      </info-modal>

      <confirmation-modal
        ref="deletionConfirmationModal"
        header="Delete seat"
        question="Seat will be deleted permanently and will not be included in your next billing cycle."
        confirmBtnText="Confirm deletion"
        cancelBtnText="Cancel"
        confirmBtnType="danger"
        @on-accept="deleteSeat"
      />
      <add-seats-modal ref="addSeatsModal" />
    </div>
</template>

<script>
import {seatStatus} from '@/store/userConstants';
import ConfirmationModal from '@/components/App/modals/ConfirmationModal.vue';
import {mapGetters, mapActions} from 'vuex';
import {DELETE_SEAT_FROM_ACCOUNT_REQUEST} from '@/store/storeActions';
import AddSeatsModal from './AddSeatsModal.vue';
import InfoModal from '@/components/App/modals/InfoModal.vue';

export default {
  components: {
    ConfirmationModal,
    AddSeatsModal,
    InfoModal,
  },
  props: {
  },
  data: function() {
    return {
      currentSeat: null,
      seatStatus: seatStatus,
    };
  },
  computed: {
    ...mapGetters([
      'totalSeats',
      'availableSeats',
      'accountSeats',
    ]),
    seats() {
      return this.accountSeats.filter(
        (seat) => seat.status === seatStatus.USED || seat.status === seatStatus.PENDING_INVITATION);
    },
    seatsFilled() {
      return this.availableSeats >= this.totalSeats;
    },
  },
  methods: {
    ...mapActions([
      DELETE_SEAT_FROM_ACCOUNT_REQUEST,
    ]),
    deleteSeatsHandler() {
      this.seatsFilled ? this.$refs.infoModal.openModal() : this.$refs.deletionConfirmationModal.openModal();
    },
    deleteSeat: function() {
      this.DELETE_SEAT_FROM_ACCOUNT_REQUEST({});
    },
    showAdditionSeatsModal: function() {
      this.$refs.addSeatsModal.displayToggle(true);
    },
  },
};
</script>
