<template>
  <div class="invite-member-modal">
    <modal
      :show="display"
      v-on:close="closeModal"
      footerClasses="d-none"
      headerClasses="app-border-b align-items-center"
    >
      <template v-slot:header>
          <h5 class="modal-title" id="exampleModalLabel">{{header}}</h5>
      </template>
      <div class="my-2 mb-5">
        <slot name="body"></slot>
      </div>
    </modal>
  </div>
</template>
<script>

export default {
  props: {
    header: String,
  },
  data() {
    return {
      display: false,
      confirmData: null,
    };
  },
  methods: {
    openModal(confirmData = null) {
      this.confirmData = confirmData;
      this.display = true;
    },
    closeModal: function() {
      setTimeout(() => {
        this.display = false;
      });
    },
  },
};
</script>
