<template>
  <div class="invite-member-modal">
    <modal
      :show="display"
      v-on:close="displayToggle(false)"
      footerClasses="d-none"
      headerClasses="app-border-b align-items-center"
    >
      <template v-slot:header>
        <h5 class="modal-title" id="exampleModalLabel">Invite a new team member to fill an open seat</h5>
      </template>
      <div class="mt-2 mb-5 px-3">The new team member will receive a welcome email with instructions
        on how to join your team shortly, ask them to check their spam/junk folders if they don’t see it.
      </div>
      <ValidationObserver v-slot="{ invalid, validate }" ref="formValidator">
        <form
          @submit.prevent="validate().then(submit)"
          class="needs-validation"
        >
          <div class="mb-2">
            <b>New team member’s email</b>
          </div>
          <div>
            <base-input
              type="email"
              placeholder="Email Address"
              name="Email"
              v-model="email"
              :rules="`required|email|isBusy:${usedEmails}`"
              @keydown="closeModalPreventHandler"
            ></base-input>
          </div>
          <div class="mb-2">
            <b>Select account type</b>
          </div>
          <b-form-group class="mb-5 fw-bold d-block">
            <b-form-radio name="invite-type" v-model="invitationType" value="member_texter" :disabled="seatsFilled">
              <span class="ml-2 font-500">Member</span>
            </b-form-radio>
            <b-form-radio name="invite-type" v-model="invitationType" value="admin_texter" :disabled="seatsFilled">
              <span class="ml-2 font-500">Admin with texting</span>
            </b-form-radio>
            <b-form-radio name="invite-type" v-model="invitationType" value="admin_nonTexter">
              <span class="ml-2 font-500">Admin without texting (free seat)</span>
            </b-form-radio>
          </b-form-group>
          <div class="d-flex justify-content-end">
            <button
              class="btn btn-outline-primary border-0"
              @click.prevent="displayToggle(false)"
            >
              Cancel
            </button>
            <button class="btn btn-primary" type="submit" :disabled="invalid || invitationType === ''">
              <i class="ni ni-send"></i>
              Send Invite
            </button>
          </div>
        </form>
      </ValidationObserver>
    </modal>
  </div>
</template>
<script>
import {mapActions} from 'vuex';
import {INVITE_USER_REQUEST} from '@/store/storeActions';
import {seatTypes, userTypes} from '@/store/userConstants';

export default {
  data() {
    return {
      display: false,
      email: '',
      userTypes,
      userType: userTypes.MEMBER,
      seatTypes,
      seatType: seatTypes.NON_TEXTER,
      invitationType: '',
    };
  },
  props: {
    usedEmails: Array,
    seatsFilled: Boolean,
  },
  methods: {
    ...mapActions(
      [INVITE_USER_REQUEST],
    ),
    displayToggle: function(isDisplay) {
      this.display = isDisplay;
      isDisplay && (this.email = '');
    },
    submit: function() {
      this.processInvitationType();
      this.INVITE_USER_REQUEST({
        email: this.email,
        userType: this.userType,
        seatType: this.seatType,
      });
      this.invitationType = '';
      this.displayToggle(false);
    },
    processInvitationType: function() {
      switch (this.invitationType) {
        case 'member_texter':
          this.userType = this.userTypes.MEMBER;
          this.seatType = this.seatTypes.TEXTER;
          break;
        case 'admin_texter':
          this.userType = this.userTypes.ADMIN;
          this.seatType = this.seatTypes.TEXTER;
          break;
        case 'admin_nonTexter':
          this.userType = this.userTypes.ADMIN;
          this.seatType = this.seatTypes.NON_TEXTER;
          break;
      }
    },
    closeModalPreventHandler(e) {
      if (e.key === 'Enter') {
        e.preventDefault();
        this.$refs.formValidator.validate().then((isSuccess) => isSuccess && this.submit());
      }
    },
  },
};
</script>
